import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DocumentReceivedModel } from "../model/dte-received/DteReceivedModel";

export interface documentReceivedReducerModel {
  loading: boolean;
  documents: DocumentReceivedModel[];
  error: string;
}

const initialState = {
  loading: false,
  documents: Array<DocumentReceivedModel>(),
  error: "",
};

const DocumentReceivedSlice = createSlice({
  name: "document-Received",
  initialState,
  reducers: {
    documentReceivedPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    documentReceivedSuccess: (state, action: PayloadAction<DocumentReceivedModel[]>) => {
      state.documents = action.payload;
    },
    documentReceivedError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { documentReceivedPending, documentReceivedSuccess, documentReceivedError } = DocumentReceivedSlice.actions;
export default DocumentReceivedSlice.reducer;