import { createSlice } from "@reduxjs/toolkit";
import { UserDetailModel } from "./../model/user/UserModel";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserReducerModel {
    loading: boolean;
    users: UserDetailModel[];
    error: string;
  }
  
  const initialState: UserReducerModel = {
    loading: false,
    users: Array<UserDetailModel>(),
    error: "",
  };
  
  export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        usersPending: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
          },
        usersSuccess: (state, action: PayloadAction<UserDetailModel[]>) => {
            state.users = action.payload;
          },
        usersError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
          },
    }
  });

export const { usersPending, usersError, usersSuccess } = UserSlice.actions;
export default UserSlice.reducer;