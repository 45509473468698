import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DashboardElementsModel } from "../model/dashboard/DashboardModel";
import { DocumentModel, CafModel } from "../model/dashboard/DashboardModel";
import { CertificateDetailModel } from '../model/certificate/CertificateModel';

export interface DashboardReducerModel {
  loading: boolean;
  information: DashboardElementsModel;
  error: string;
}
const initialState: DashboardReducerModel = {
  loading: false,
  information: { 
        sales: new Array<DocumentModel>(), 
        purchases: new Array<DocumentModel>(), 
        caf: new Array<CafModel>(), 
        certificate: new Array<CertificateDetailModel>() },
  error: "",
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    dashboardSuccess: (state, action: PayloadAction<DashboardElementsModel>) => {
      state.information = action.payload;
    },
    dashboardError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { dashboardPending, dashboardSuccess, dashboardError } = DashboardSlice.actions;
export default DashboardSlice.reducer;
