import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TicketDetailModel, HistorySbisModel } from "../model/ticket/TicketModel";

export interface TicketReducerModel {
  loading: boolean;
  tickets: TicketDetailModel[];
  history: HistorySbisModel[];
  error: string;
}

const initialState = {
  loading: false,
  tickets: Array<TicketDetailModel>(),
  history: Array<HistorySbisModel>(),
  error: "",
};

export const TicketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    ticketPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    ticketSuccess: (state, action: PayloadAction<TicketDetailModel[]>) => {
      state.tickets = action.payload;
    },
    ticketHistory: (state, action: PayloadAction<HistorySbisModel[]>) => {
      state.history = action.payload;
    },
    ticketError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { ticketPending, ticketError, ticketHistory, ticketSuccess } = TicketSlice.actions;
export default TicketSlice.reducer;