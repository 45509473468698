import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DocumentModel, DocumentHistoryModel, HistoryModel } from "../model/dte-issued/DteIssuedModel";

export interface documentIssuedReducerModel {
  loading: boolean;
  documents: DocumentModel[];
  history: DocumentHistoryModel[];
  error: string;
}

const initialState = {
  loading: false,
  documents: Array<DocumentModel>(),
  history: Array<HistoryModel>(),
  error: "",
};

const DocumentIssuedSlice = createSlice({
  name: "document-issued",
  initialState,
  reducers: {
    documentIssuedPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    documentIssuedSuccess: (state, action: PayloadAction<DocumentModel[]>) => {
      state.loading = false;
      state.documents = action.payload;
    },
    documentIssuedHistory: (state, action: PayloadAction<HistoryModel[]>) => {
      state.history = action.payload;
    },
    documentIssuedError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { documentIssuedPending, documentIssuedSuccess, documentIssuedHistory, documentIssuedError } =
  DocumentIssuedSlice.actions;
export default DocumentIssuedSlice.reducer;