import { combineReducers } from 'redux';
import AuthReducer from './auth.reducer';
import UserReducer from './user.reducer';
import CompanyReducer from './company.reducer';
import PermissionReducer from './permission.reducer';
import DailySalesSummaryReducer from './daily-sales-report.reducer';
import DashboardReducer from './dashboard.reducer';
import SubscriptionReducer from './subscription.reducer';
import MonitorReducer from './monitor.reducer';
// documentos
import TicketReducer from './ticket.reducer';
import DocumentIssuedReducer from './document-issued.reducer';
import DocumentReceivedReducer from './document-received.reducer';
import CafReducer from './caf.reducer';
import CertificateReducer from './certificate.reducer';

// Root reducers
export const rootReducer = combineReducers({
      auth: AuthReducer,
      user: UserReducer,
      company: CompanyReducer,
      permission: PermissionReducer,
      ticket: TicketReducer,
      dailySalesSummary: DailySalesSummaryReducer,
      issued: DocumentIssuedReducer,
      received: DocumentReceivedReducer,
      caf: CafReducer,
      dashboard: DashboardReducer,
      certificate: CertificateReducer,
      subscription: SubscriptionReducer,
      monitor: MonitorReducer
})
export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
