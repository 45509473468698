import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AuthModel } from "./../model/auth/AuthModel";

export interface authReducerModel {
  loading: boolean;
  information: AuthModel;
  error: string;
}

const initialState: authReducerModel = {
  loading: false,
  information: {
    access_token: "",
    token_type: "",
    expires_in: +Date.now(),
  },
  error: "",
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLoginPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    authLoginSuccess: (state, action: PayloadAction<AuthModel>) => {
      state.information = action.payload;
    },
    authLoginError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { authLoginPending, authLoginError, authLoginSuccess } = AuthSlice.actions;
export default AuthSlice.reducer;
