import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loading } from "./components/common/loading/loading";
// Componentes contenido sitio administracion
import { NotFound } from "./components/common/not-found/not-found";
import 'antd/dist/reset.css';

const Login = lazy(() => import('./components/admin/loginExport'));
const DteState = lazy(() => import('./components/site/dte-state/dte-stateExport'));
const Home = lazy(() => import('./components/site/homeExport'));
const Password = lazy(() => import('./components/site/password/passwordExport'));
const Main = lazy(() => import("./components/admin/main/mainExport"));

export const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/restore" element={<Password />} />
          <Route path="/dte-state" element={<DteState />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/*" element={<Main />} />          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
