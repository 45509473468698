import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MonitorDetailModel } from "../model/monitor/MonitorModel";

export interface monitorReducerModel {
  loading: boolean;
  registries: MonitorDetailModel[];
  error: string;
}

const initialState: monitorReducerModel = {
  loading: false,
  registries: Array<MonitorDetailModel>(),
  error: "",
};

export const MonitorSlice = createSlice({
  name: "monitor",
  initialState,
  reducers: {
    MonitorPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    MonitorSuccess: (state, action: PayloadAction<MonitorDetailModel[]>) => {
      state.registries = action.payload;
    },
    MonitorError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { MonitorPending, MonitorSuccess, MonitorError } = MonitorSlice.actions;
export default MonitorSlice.reducer;