import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CertificateDetailModel } from '../model/certificate/CertificateModel';

export interface certificateReducerModel {
  loading: boolean;
  certificates: CertificateDetailModel[];
  error: string;
}

const initialState: certificateReducerModel = {
  loading: false,
  certificates: Array<CertificateDetailModel>(),
  error: "",
};

export const CertificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    CertificatePending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    CertificateSuccess: (state, action: PayloadAction<CertificateDetailModel[]>) => {
      state.certificates = action.payload;
    },
    CertificateError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { CertificatePending, CertificateSuccess, CertificateError } = CertificateSlice.actions;
export default CertificateSlice.reducer;