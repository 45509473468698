import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CafDetailModel } from "./../model/caf/CafModel";

export interface cafReducerModel {
  loading: boolean;
  cafs: CafDetailModel[];
  error: string;
}

const initialState: cafReducerModel = {
  loading: false,
  cafs: Array<CafDetailModel>(),
  error: "",
};

export const CafSlice = createSlice({
  name: "caf",
  initialState,
  reducers: {
    CafPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    CafSuccess: (state, action: PayloadAction<CafDetailModel[]>) => {
      state.cafs = action.payload;
    },
    CafError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { CafPending, CafSuccess, CafError } = CafSlice.actions;
export default CafSlice.reducer;