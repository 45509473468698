import React from "react";
import "./loading.scss";
import { Layout, Spin } from "antd";

export const Loading = () => {
  return (
    <Layout style={{ height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <Spin tip="Cargando..." size="large"></Spin>
    </Layout>
  );
};