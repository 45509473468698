import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CompanyDetailModel } from "../model/company/CompanyModel";

export interface CompanyReducerModel {
  loading: boolean;
  companies: CompanyDetailModel[];
  error: string;
}

const initialState: CompanyReducerModel = {
  loading: false,
  companies: Array<CompanyDetailModel>(),
  error: "",
};

export const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companyPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    companySuccess: (state, action: PayloadAction<CompanyDetailModel[]>) => {
      state.companies = action.payload;
    },
    companyError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.companies = [];
    },
  },
});

export const { companyPending, companySuccess, companyError } = CompanySlice.actions;
export default CompanySlice.reducer;
