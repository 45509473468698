import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, la página consultada no existe."
      extra={<Button type="primary" onClick={(e) => navigate("/admin/dashboard")}>Volver al inicio</Button>}
    />
  );
};
