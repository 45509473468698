import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DailySalesSummaryDetailModel, DailySalesSummaryHistoryModel } from '../model/daily-sales-summary/DailySalesSummaryModel';

export interface DSSReducerModel {
loading: boolean;
dss: DailySalesSummaryDetailModel[];
history: DailySalesSummaryHistoryModel[]; 
error: string;
}

const initialState: DSSReducerModel = {
    loading: false,
    dss: Array<DailySalesSummaryDetailModel>(),
    history: Array<DailySalesSummaryHistoryModel>(),
    error: ''
}

export const DailySalesSummarySlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        DSSPending: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
          },
        DSSSuccess: (state, action: PayloadAction<DailySalesSummaryDetailModel[]>) => {
            state.dss = action.payload;
          },
        DSSHistory: (state, action: PayloadAction<DailySalesSummaryHistoryModel[]>) => {            
            state.history = action.payload;
          },
        DSSError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
          },
    }
});

export const { DSSPending, DSSSuccess, DSSHistory, DSSError } = DailySalesSummarySlice.actions;
export default DailySalesSummarySlice.reducer;