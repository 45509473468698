import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PermissionDetailModel } from '../model/permission/PermissionModel';

export interface PermissionReducerModel {
  permissions: PermissionDetailModel[];
  error: string;
}

const initialState = {
  permissions: new Array<PermissionDetailModel>() || [],
  error: "",
};

export const PermissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    permissionSuccess: (state, action: PayloadAction<PermissionDetailModel[]>) => {
      state.permissions = action.payload;
    },
    permissionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});
export const { permissionError, permissionSuccess } = PermissionSlice.actions;
export default PermissionSlice.reducer;