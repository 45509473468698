import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionDetailModel } from "../model/subscription/SubscriptionModel";

export interface subscriptionReducerModel {
  loading: boolean;
  subscriptions: SubscriptionDetailModel[];
  error: string;
}

const initialState: subscriptionReducerModel = {
  loading: false,
  subscriptions: Array<SubscriptionDetailModel>(),
  error: "",
};

export const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    SubscriptionPending: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    SubscriptionSuccess: (state, action: PayloadAction<SubscriptionDetailModel[]>) => {
      state.subscriptions = action.payload;
    },
    SubscriptionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { SubscriptionPending, SubscriptionSuccess, SubscriptionError } = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;