import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./main-styles.scss";
import { App } from "./app";
import { store } from "./store/store";
import { ConfigProvider } from "antd";
import esES from 'antd/locale/es_ES';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <Provider store={store}>
      <ConfigProvider locale={esES}>
        <App />
      </ConfigProvider>
    </Provider>
  </>
);